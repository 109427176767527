



import Vue from 'vue';

import SignUpContainer from '@/container/auth/SignUpContainer.vue';

export default Vue.extend({
  name: 'SignUpScene',

  components: {
    SignUpContainer,
  },
});
