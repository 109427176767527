




























































































































































import LocalDataService from '@/service/LocalDataServices';
import { auth0Module } from '@/store/viewModule/auth/auth0Module';
import { signUpModule } from '@/store/viewModule/auth/signUpModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import Vue from 'vue';

interface DataType {
  email: string;
  password: string;
  passwordConfirm: string;
  username: string;
  firstName: string;
  lastName: string;
  teamId: string;
  teamName: string;
  isValid: boolean;
  isAgreed: boolean;
}



export default Vue.extend({
  name: 'SignUpContainer',

  data: (): DataType => {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      username: '',
      firstName: '',
      lastName: '',
      teamId: '',
      teamName: '',
      isValid: false,
      isAgreed: false,
    };
  },

  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('validator.required'),
        email: (email) => {
          const emailRegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_._-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
          return emailRegExp.test(email) || this.$t('validator.email');
        },
        passwordLength: (password) => {
          return password.trim().length >= 8 || this.$t('validator.passwordLength');
        },
        password: (password) => {
          const passwordRegExp = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]{1,100}$/;
          return passwordRegExp.test(password) || this.$t('validator.passwordChars');
        },
      };
    },
    isPaswordConfirmed() {
      if (this.password.trim().length === 0 || this.passwordConfirm.trim().length === 0) {
        return false;
      }
      return this.password === this.passwordConfirm;
    },
    showLoader() {
      return commonModule.showLoader;
    },
  },

  methods: {
    signup() {
      const locale = this.$i18n.locale;
      commonModule.setLoader(true);
      signUpModule.signUp({
        teamId: this.teamId,
        name: this.teamName,
        username: this.username,
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        language: locale,
      }).then((data) => {
        commonModule.setLoader(false);
        const response = data;
        if (response && response.sub) {
          alert(this.$t('confirmationEmailSent'));
        } else if (data.error) {
          switch (data.error) {
            case 'E005':
              alert(this.$t('errorE005'));
              break;
            case 'E020':
              alert(this.$t('errorE020'));
              break;
            case 'E038':
              alert(this.$t('errorE038'));
              break;
            case 'E039':
              alert(this.$t('errorE039'));
              break;
            case 'E040':
              alert(this.$t('errorE040'));
              break;
            case 'E041':
              alert(this.$t('errorE041'));
              break;
            case 'E042':
              alert(this.$t('errorE042'));
              break;
            case 'E043':
              alert(this.$t('errorE043'));
              break;
            default:
              alert(this.$t('errorAuthAlert'));
              break;
          }
        }
      }).catch(() => {
        commonModule.setLoader(false);
        alert(this.$t('errorSignup'));
      });
    },
  },

  async mounted() {
  },
});
