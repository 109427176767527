import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { signUp } from '@/api/client';

export interface SignUpState {}

@Module({ dynamic: true, store, name: 'sign-up', namespaced: true })
class SignUpModule extends VuexModule implements SignUpState {

  @Action
  public async signUp({
    teamId,
    name,
    email,
    password,
    username,
    language,
    firstName,
    lastName,
  }: {
    teamId: string,
    name: string,
    email: string,
    password: string,
    username: string,
    language: string,
    firstName: string,
    lastName: string,
  }) {
    return signUp({
      teamId,
      name,
      email,
      password,
      username,
      language,
      firstName,
      lastName,
    });
  }
}

export const signUpModule = getModule(SignUpModule);
